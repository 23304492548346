import $ from 'jquery';

import LocationContext from 'chairisher/context/location';
import RailUtils from 'chairisher/util/rail';
import RepeatingScroller from 'chairisher/component/scroller/repeating';

import { trackRailLinkClick, trackRailView } from 'chairisher/analytics/components/rail';
import { observeIntersectionForElsOnce } from 'chairisher/view/helper/intersectionobserver';
import {
    HOUSE_AD_CLASS,
    PROMOTED_LISTING_CLASS,
    registerProductImpression,
} from 'chairisher/view/helper/promotedlistings';
import { updateProductIdToProductObjMap, updateProductObjectArray } from 'chairisher/context/product';

/**
 * The maximum number of products permitted before a rail becomes scrollable
 */
const MAX_NUM_PRODUCTS_FOR_NON_SCROLLABLE_RAIL = 6;

/**
 * The minimum number of products required for a rail to be displayed, if it contains a threshold amount
 */
const MIN_NUM_PRODUCTS_TO_DISPLAY_THRESHOLD = 3;

/**
 * The number of products required for the rail's scroll to behave properly
 */
const NUM_PRODUCTS_TO_SCROLL_PROPERLY = 24;

/**
 * @param {string} endpointUrl The endpoint to hit to retrieve the URL. Note: The URL must include grid_type, product_id, and limit
 * @param {Object} options Collection of options to pass the endpoint
 * @param {jQuery} options.$containerEl The element containing the rail
 * @param {jQuery} options.$el The element representing the rail
 * @param {AnalyticsUtils.Tracking.POSITION=} options.analyticsTrackingPosition The position to identify this element's position in various analytics reports
 * @param {AnalyticsUtils.Tracking.SUB_TYPE=} options.analyticsTrackingSubtype The sub-type of the product rail to identify this element in analytics reports
 * @param {AnalyticsUtils.Tracking.TYPE=} options.analyticsTrackingType The type of the product rail to identify this element in analytics reports
 * @param {boolean=} options.canScroll An optional argument to indicate if the rail can scroll, provided it has enough products
 * @param {Array.<number>=} options.excludeIds An optional array of product ids to exclude in order to avoid duplicates
 * @param {boolean=} options.shouldRestrictToShipsTo An optional argument to only display products that can be shipped to the user
 * @param {boolean=} options.shouldShowBadging An optional argument for the product to show badges
 * @param {boolean=} options.shouldShowDetails An optional argument for the product to show details (badges, title, price)
 * @param {string=} options.sort An optional string to indicate a variation to the sort order (e.g. 'alt')
 *
 */
class ProductRail {
    constructor(
        endpointUrl = null,
        {
            $containerEl = null,
            $el = null,
            analyticsTrackingPosition = null,
            analyticsTrackingSubtype = null,
            analyticsTrackingType = null,
            canScroll = true,
            excludeIds = null,
            shouldRestrictToShipsTo = false,
            shouldShowBadging = true,
            shouldShowDetails = true,
            sort = null,
        } = {},
    ) {
        this.$containerEl = $containerEl;
        this.$el = $el;
        this.analyticsTrackingPosition = analyticsTrackingPosition;
        this.analyticsTrackingSubtype = analyticsTrackingSubtype;
        this.analyticsTrackingType = analyticsTrackingType;
        this.canScroll = canScroll;
        this.endPointUrl = endpointUrl;
        this.excludeIds = excludeIds;
        this.shouldRestrictToShipsTo = shouldRestrictToShipsTo;
        this.shouldShowBadging = shouldShowBadging;
        this.shouldShowDetails = shouldShowDetails;
        this.sort = sort;

        this.bindAnalytics();
    }

    /**
     * Fetches data from the server and populates the rail
     *
     * @returns {jqXHR}
     */
    fetchData() {
        if (!this.$el || !this.$el.length) {
            return $.Deferred().reject();
        }

        const data = { is_rail: true };

        if ($.isArray(this.excludeIds) && this.excludeIds.length) {
            data.exclude_ids = this.excludeIds.join(',');
        }
        if (this.shouldShowDetails) {
            data.should_show_details = true;
        }
        if (this.sort) {
            data.sort = this.sort;
        }
        if (this.shouldShowBadging) {
            data.shouldShowBadging = this.shouldShowBadging;
        }
        if (
            this.shouldRestrictToShipsTo &&
            ![LocationContext.countryCodes.anonymous, LocationContext.countryCodes.unknown].includes(
                LocationContext.getPrimaryCountry(),
            )
        ) {
            data.ships_to = LocationContext.getPrimaryCountry();
        }

        return $.ajax({
            data,
            url: this.endPointUrl,
        }).done((responseData) => {
            // TODO: (CHAIR-14874) update all rail implementations to send down product_id_to_product_json
            let productIdToProductJson = responseData.product_id_to_product_json;
            if (Object.values(productIdToProductJson || {}).length === 0) {
                productIdToProductJson = (responseData.products || []).reduce(
                    (o, p) => Object.assign(o, { [p.id]: p }),
                    {},
                );
            }
            updateProductIdToProductObjMap(productIdToProductJson);
            const products = Object.values(productIdToProductJson);
            let productsHtml = responseData.products_html || [];

            if (products.length && productsHtml.length) {
                const isRailScrollable = this.canScroll && products.length > MAX_NUM_PRODUCTS_FOR_NON_SCROLLABLE_RAIL;
                if (isRailScrollable) {
                    this.fillOutProductRail(products);
                    productsHtml = this.fillOutProductRail(productsHtml);
                }

                if (responseData.product_grid_title) {
                    this.$containerEl.find('.js-product-grid-title').text(responseData.product_grid_title);
                }

                if (responseData.view_all_url) {
                    const $link = this.$containerEl.find('.js-view-all-link');
                    $link.attr({
                        href: responseData.view_all_url,
                        rel: 'nofollow',
                    });
                }

                this.$el.html(productsHtml);

                // if the rail does not have a minimum product threshold, it can be shown at this stage
                const isMinProductRail = this.$containerEl.hasClass('min-product-rail');
                if (!isMinProductRail) {
                    this.$containerEl.removeClass('retracted');
                }

                updateProductObjectArray(products);

                const container = this.$el.get(0);
                const children = this.getChildEls().get();
                const $children = $(children);

                if (isRailScrollable) {
                    new RepeatingScroller({
                        analyticsTrackingPosition: this.analyticsTrackingPosition,
                        analyticsTrackingSubtype: this.analyticsTrackingSubtype,
                        analyticsTrackingType: this.analyticsTrackingType,
                        container,
                        children,
                        shouldAutoScroll: false,
                    });
                } else {
                    // sets container height both at load and whenever the screen is resized
                    // this is already performed on scrollable rails, but needed on non-scrollable as well
                    const setContainerHeight = () => {
                        // TODO: (CHAIR-16614) Roll out dynamic heights to all rails, if desired and approved by design
                        if (this.$containerEl.hasClass('dynamic-height-rail')) {
                            container.style.height = RailUtils.calculateContainerHeight({ $children, container });
                        } else {
                            container.style.height = `${children[0].offsetHeight}px`;
                        }
                    };
                    setContainerHeight();
                    $(window).smartresize(setContainerHeight);
                }

                // if the rail does have a minimum product threshold, and it has been reached, it can be shown at this stage
                if (isMinProductRail && $children.length >= MIN_NUM_PRODUCTS_TO_DISPLAY_THRESHOLD) {
                    this.$containerEl.css('margin-bottom', '20px'); // included here to avoid a "loading flash" on uhp
                    this.$containerEl.removeClass('retracted');
                }

                if (responseData.promoted_listing_product_ids?.length > 0) {
                    this.#handlePromotedListings(responseData.promoted_listing_product_ids);
                }

                trackRailView({
                    position: this.analyticsTrackingPosition,
                    subtype: this.analyticsTrackingSubtype,
                    type: this.analyticsTrackingType,
                });
            } else {
                this.$containerEl.addClass('hidden');
            }
        });
    }

    /**
     * Binds analytics to the various elements of the rail
     */
    bindAnalytics() {
        this.$containerEl.on('click', '.js-view-all-link', (e) => {
            trackRailLinkClick({
                e,
                type: this.analyticsTrackingType,
            });
        });

        this.$containerEl.on('click', '.js-product-link', (e) => {
            trackRailLinkClick({
                e,
                isProduct: true,
                type: this.analyticsTrackingType,
            });
        });
    }

    /**
     * Duplicates the products in the array until it meets the number of products necessary to ensure proper scroll behavior
     *
     * @param {array} productArray the array of products to be updated; length should be less than NUM_PRODUCTS_TO_SCROLL_PROPERLY
     * @returns {array} the new array; length should now be equal to NUM_PRODUCTS_TO_SCROLL_PROPERLY
     */
    fillOutProductRail(productArray) {
        return Array.from({ length: NUM_PRODUCTS_TO_SCROLL_PROPERLY }, (_, i) => productArray[i % productArray.length]);
    }

    /**
     * @returns {jQuery} The children in this rail
     */
    getChildEls() {
        return this.$el.children('.product');
    }

    #handlePromotedListings(promotedListingProductIds) {
        const $childEls = this.getChildEls();
        $childEls.each((_, el) => {
            const $el = $(el);
            const productId = parseInt($el.data('product-id'), 10);
            if (promotedListingProductIds.includes(productId)) {
                $el.addClass(PROMOTED_LISTING_CLASS);
            } else {
                $el.addClass(HOUSE_AD_CLASS);
            }
        });

        observeIntersectionForElsOnce($childEls, ({ target }) => {
            registerProductImpression($(target), this.analyticsTrackingType, this.analyticsTrackingSubtype);
        });
    }
}

export default ProductRail;
