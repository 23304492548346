const ChairishContext = window.chairisher.context;

/**
 * @returns {string} The type of business being registered or referenced on the page
 */
export function getBusinessType() {
    return ChairishContext.BUSINESS_TYPE;
}

/**
 * List of business ids followed by the current account.
 *
 * @return {Array.<number>}
 */
export function getFollowedBusinessIds() {
    return ChairishContext.FOLLOWED_BUSINESS_IDS;
}

/**
 * @returns {string}
 */
export function getBusinessRailEndpoint() {
    return ChairishContext.BUSINESS_RAIL_ENDPOINT;
}

/**
 * @returns {Object} businessRailData Object mapping business id to data related to that business for a business rail
 */
export function getBusinessRailData() {
    return ChairishContext.BUSINESS_RAIL_DATA || {};
}

/**
 * @param {Object} businessRailData Object mapping business id to data related to that business for a business rail
 */
export function setBusinessRailData(businessRailData) {
    ChairishContext.BUSINESS_RAIL_DATA = businessRailData;
}
