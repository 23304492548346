import $ from 'jquery';

import ProductModel from 'chairisher/model/product';

import { trackFavoritingEvent } from 'chairisher/analytics/product';
import { trackRemarketingEvent } from 'chairisher/util/analytics';
import { abbreviateNumber } from 'chairisher/util/format';
import { getQuickCreateUrl, isAuthenticated } from 'chairisher/context/auth';
import {
    canRenderFavoriteHearts,
    getDataForVariantId,
    getFavoriteCount,
    getFavoriteCreateUrl,
    getFavoriteDeleteUrl,
    getFavoriteLookupUrl,
    getProductObjectById,
    setFolderedProductIds,
    UNIT_TYPE_SAMPLE,
} from 'chairisher/context/product';

/**
 * Manages view layer functionality for favoriting products
 *
 * @constructor
 * @singleton
 * @class FavoriteProductView
 */
const FavoriteProductView = function () {
    this._favoriteCount = getFavoriteCount();
    this._productIds = [];
    this._favoriteCountSelectors = [];
};

/**
 * @type {number}
 * @private
 */
FavoriteProductView.prototype._anonymousFavoriteThreshold = 1;

/**
 * A deferred to keep track of inflight data
 *
 * @type {$.Deferred}
 * @private
 */
FavoriteProductView.prototype._$dataUpdateDeferred = null;

/**
 * The number of products the current user has favorited
 *
 * @type {number}
 * @private
 */
FavoriteProductView.prototype._favoriteCount = 0;

/**
 * @type {Array.<string>}
 * @private
 */
FavoriteProductView.prototype._favoriteCountSelectors = null;

/**
 * @type {string}
 * @private
 */
FavoriteProductView.prototype._favoriteButtonSelector = '';

/**
 * @type {string}
 * @private
 */
FavoriteProductView.prototype._productFavoriteCountSelector = '';

/**
 * @type {string}
 * @private
 */
FavoriteProductView.prototype._favoriteTrackingLabel = '';

/**
 * @type {Array.<number>}
 * @private
 */
FavoriteProductView.prototype._productIds = null;

/**
 * @param {Array.<string>} favoriteCountSelectors The list of element selectors listening for updates
 */
FavoriteProductView.prototype.setFavoriteCountSelectors = function (favoriteCountSelectors) {
    if ($.isArray(favoriteCountSelectors)) {
        this._favoriteCountSelectors = favoriteCountSelectors;
    }
};

/**
 * @returns {Array.<string>} The list of element selectors listening for updates
 */
FavoriteProductView.prototype.getFavoriteCountSelectors = function () {
    return this._favoriteCountSelectors;
};

/**
 * @param {string} productFavoriteCountSelector The element selector stub listening for updates
 */
FavoriteProductView.prototype.setProductFavoriteCountSelector = function (productFavoriteCountSelector) {
    this._productFavoriteCountSelector = productFavoriteCountSelector;
};

/**
 * @returns {string} The element selector stub listening for updates
 */
FavoriteProductView.prototype.getProductFavoriteCountSelector = function () {
    return this._productFavoriteCountSelector;
};

/**
 * @param {string} favoriteButtonSelector The selector for the favorite button
 */
FavoriteProductView.prototype.setFavoriteButtonSelector = function (favoriteButtonSelector) {
    this._favoriteButtonSelector = favoriteButtonSelector;
};

/**
 * @returns {string} The selector for the favorite button
 */
FavoriteProductView.prototype.getFavoriteButtonSelector = function () {
    return this._favoriteButtonSelector;
};

/**
 * @param {string} The tracking label for the favorite button
 */
FavoriteProductView.prototype.setFavoriteTrackingLabel = function (label) {
    this._favoriteTrackingLabel = label;
};

/**
 * @returns {string} The tracking label for the favorite button
 */
FavoriteProductView.prototype.getFavoriteTrackingLabel = function () {
    return this._favoriteTrackingLabel;
};

/**
 * Updates the current user's total favorite count
 * @param {number} opt_count
 */
FavoriteProductView.prototype.updateFavoriteCount = function (opt_count) {
    const selectors = this.getFavoriteCountSelectors();
    if (selectors && selectors.length) {
        const selector = this.getFavoriteCountSelectors().join(',');
        if (opt_count != null) {
            this._favoriteCount = opt_count;
        }

        $(selector).text(abbreviateNumber(this._favoriteCount) || '');
    }
};

/**
 * Checks the server to see which product ids that are currently present on the page are favorites (if any).
 *
 * @returns {$.Deferred}
 */
FavoriteProductView.prototype.updateFavoriteProductIds = function () {
    if (this._$dataUpdateDeferred !== null && this._$dataUpdateDeferred.state() === 'pending') {
        return this._$dataUpdateDeferred;
    }

    this._$dataUpdateDeferred = $.Deferred();

    if (isAuthenticated()) {
        const $products = $('[data-product-id]');
        const idsOnPage = new Set();
        $products.each((i) => {
            const id = $products.eq(i).data('product-id');
            if (id && Number.isFinite(id)) {
                idsOnPage.add(id);
            }
        });
        if (idsOnPage.size > 0) {
            $.ajax({
                url: getFavoriteLookupUrl(),
                data: {
                    product_ids: Array.from(idsOnPage).join(','),
                },
            }).done(
                $.proxy(function (data) {
                    const favoritedProductIds = [];
                    const folderedProductIds = []; // TODO: (CHAIR-9033) move this into a more appropriate place

                    if (data.action_info) {
                        for (let i = 0; i < data.action_info.length; i++) {
                            const info = data.action_info[i];
                            if (info.is_favorited) {
                                favoritedProductIds.push(info.id);
                            }
                            if (info.is_in_folder) {
                                folderedProductIds.push(info.id);
                            }
                        }
                    }

                    this.setProductIds(favoritedProductIds);
                    setFolderedProductIds(folderedProductIds);

                    if (canRenderFavoriteHearts()) {
                        $('.js-favorite-button.transparent').removeClass('transparent');
                    }

                    this._$dataUpdateDeferred.resolve(data);
                }, this),
            );
        } else {
            this._$dataUpdateDeferred.resolve();
        }
    }

    return this._$dataUpdateDeferred;
};

/**
 * @param {number} productId
 * @param {jQuery=} opt_jQueryCountElement
 */
FavoriteProductView.prototype.updateProductFavoriteCount = function (productId, opt_jQueryCountElement) {
    let $productCount = opt_jQueryCountElement || [];
    if ($productCount.length === 0) {
        $productCount = $(`[data-product-id="${productId}"] ${this.getProductFavoriteCountSelector()}`);
    }

    if ($productCount.length) {
        let currentCount = $productCount.data('favorite-count');
        if (this.isProductIdFavorited(productId)) {
            currentCount += 1;
        } else {
            currentCount -= 1;
        }

        $productCount.data('favorite-count', currentCount);
        $productCount.text(currentCount < 1 ? '' : abbreviateNumber(currentCount));
    }
};

/**
 * Draws favorites buttons. Since we use an eventual consistency model this is usually called
 * after a favorite button has been initially drawn to ensure it maintains accuracy.
 *
 * @param {boolean} isFavorite
 * @param {number} productId
 * @param {jQuery=} opt_jQueryHeart
 */
FavoriteProductView.prototype.drawButton = function (isFavorite, productId, opt_jQueryHeart) {
    if (canRenderFavoriteHearts()) {
        let $buttons = opt_jQueryHeart || [];
        if ($buttons.length === 0) {
            $buttons = $(`[data-product-id=${productId}]`).find(this.getFavoriteButtonSelector());
        }

        $buttons.each(function () {
            const $this = $(this);
            $this.toggleClass('on', isFavorite);
            $this.toggleClass('off', !isFavorite);
        });
    }
};

/**
 * Draws favorite buttons for all favorited products.
 */
FavoriteProductView.prototype.drawButtons = function () {
    if (canRenderFavoriteHearts()) {
        const $buttons = $('[data-product-id]');
        const favoriteButtonSelector = this.getFavoriteButtonSelector();

        $buttons.each(
            $.proxy(function (i) {
                const $button = $buttons.eq(i);
                const id = $button.data('product-id');
                const isFavorite = this.isProductIdFavorited(id);

                if (isFavorite) {
                    const $heart = $button.find(favoriteButtonSelector);
                    this.drawButton(isFavorite, id, $heart);
                }
            }, this),
        );
    }
};

/**
 * @returns {Array.<number>}
 */
FavoriteProductView.prototype.getProductIds = function () {
    return this._productIds;
};

/**
 * @param {number} productId
 * @return {boolean} Indication of whether productId was set or not
 */
FavoriteProductView.prototype._setProductId = function (productId) {
    if (isFinite(productId)) {
        productId = parseInt(productId, 10);

        if (!this.isProductIdFavorited(productId)) {
            this._productIds.push(productId);
            return true;
        }
    }

    return false;
};

/**
 * @param {Array.<number>} productIds
 */
FavoriteProductView.prototype.setProductIds = function (productIds) {
    this._productIds = [];

    if ($.isArray(productIds)) {
        for (let i = 0; i < productIds.length; i++) {
            this._setProductId(productIds[i]);
        }
    }
};

/**
 * @param {number} productId
 * @returns {boolean}
 */
FavoriteProductView.prototype.isProductIdFavorited = function (productId) {
    productId = isFinite(productId) ? parseInt(productId, 10) : undefined;

    return $.inArray(productId, this._productIds) !== -1;
};

/**
 * @param {number} productId
 * @param {boolean=} opt_shouldPersist Defaults to true
 */
FavoriteProductView.prototype.addProductId = function (productId, opt_shouldPersist) {
    const shouldPersist = opt_shouldPersist !== undefined ? !!opt_shouldPersist : true;

    if (this._setProductId(productId)) {
        if (shouldPersist) {
            this._save(productId);
        }
    }
};

/**
 * @param {Array.<number>} productIds
 * @param {boolean=} opt_shouldPersist Defaults to true
 */
FavoriteProductView.prototype.addProductIds = function (productIds, opt_shouldPersist) {
    for (let i = 0; i < productIds.length; i++) {
        this.addProductId(productIds[i], opt_shouldPersist);
    }
};

/**
 * @param {number} productId
 * @param {boolean=} shouldPersist Defaults to true
 */
FavoriteProductView.prototype.removeProductId = function (productId, shouldPersist) {
    shouldPersist = shouldPersist !== undefined ? !!shouldPersist : true;

    if (isFinite(productId)) {
        productId = parseInt(productId, 10);

        if (this.isProductIdFavorited(productId)) {
            const index = $.inArray(productId, this._productIds);
            this._productIds.splice(index, 1);

            if (shouldPersist) {
                this._delete(productId);
            }
        }
    }
};

/**
 * Hides or displays the button text depending on the favorited status of the product
 *
 * @param {boolean} isFavorite Whether or not the product is favorited
 */
FavoriteProductView.prototype.toggleButtonText = function (isFavorite) {
    const favoriteButtonSelector = this.getFavoriteButtonSelector();
    const $favoriteButtonText = $(favoriteButtonSelector).find('.js-favorite-button-text');
    $favoriteButtonText.toggleClass('hidden', isFavorite);
};

/**
 * If the product is favorited, it will be unfavorited and vice versa.
 *
 * @param {number} productId
 * @param {boolean=} opt_shouldPersist Defaults to true
 * @param {boolean=} opt_isPurchasable Whether the product is purchasable. Defaults to true
 */
FavoriteProductView.prototype.toggleProductId = function (productId, opt_shouldPersist, opt_isPurchasable) {
    const shouldPersist = opt_shouldPersist !== undefined ? !!opt_shouldPersist : true;
    const isPurchasable = opt_isPurchasable !== undefined ? !!opt_isPurchasable : true;

    if (this.isProductIdFavorited(productId)) {
        this.removeProductId(productId, shouldPersist);
    } else {
        const $product = $(`[data-product-id="${productId}"]`);
        const productPrice = $product.data('product-price');

        if ($.isPlainObject(window.chairisher.context.RemarketingObject)) {
            if (isPurchasable) {
                trackRemarketingEvent(
                    'viewBasket',
                    [
                        {
                            id: productId,
                            price: productPrice,
                            quantity: 1,
                        },
                    ],
                    undefined,
                    true,
                );
            } else {
                trackRemarketingEvent(
                    'trackTransaction',
                    [
                        {
                            id: productId,
                            price: 0,
                            quantity: 1,
                        },
                    ],
                    `Favorites-${Math.floor(Math.random() * 100000000)}`,
                    true,
                );
            }
        }
        this.addProductId(productId, shouldPersist);
    }
};

/**
 * Saves a product in the FavoriteProductSet in the database
 *
 * @param {number} productId
 * @private
 */
FavoriteProductView.prototype._save = function (productId) {
    this._persist(false, productId);
};

/**
 * Deletes a product from the FavoriteProductSet in the database
 *
 * @param {number} productId
 * @private
 */
FavoriteProductView.prototype._delete = function (productId) {
    this._persist(true, productId);
};

/**
 * Creates or deletes a product in/from a FavoriteProductSet in the database
 *
 * @param {boolean} isDelete True indicates the product is being deleted from the FavoriteProductSet
 * @param {number} productId
 *
 * @fires FavoriteProductView#favorite.create
 * @fires FavoriteProductView#favorite.creating
 * @fires FavoriteProductView#favorite.delete
 * @fires FavoriteProductView#favorite.deleting
 *
 * @private
 */
FavoriteProductView.prototype._persist = function (isDelete, productId) {
    let url;
    if (isDelete) {
        url = getFavoriteDeleteUrl(productId);
    } else {
        url = getFavoriteCreateUrl(productId);
    }

    if (isAuthenticated() || this._productIds.length >= this._anonymousFavoriteThreshold) {
        this._favoriteCount += isDelete ? -1 : 1;
        this.updateFavoriteCount();
        const $product = $(`[data-product-id="${productId}"]`);

        // TODO: (CHAIR-9033) update favorite.js to have an element to trigger this event instead of document
        const eventName = isDelete ? 'deleting' : 'creating';
        $(document).trigger(`favorite.${eventName}`, {
            $el: $product,
            productId,
        });

        const productIds = encodeURIComponent(JSON.stringify([productId]));

        $.ajax({
            // authUrl is used in case the account isn't logged in...
            authUrl: `${getQuickCreateUrl('favorite')}&product-ids=${productIds}`,
            method: 'POST',
            url,
        })
            .done(
                $.proxy(function (data) {
                    const action = isDelete ? 'unfavorite' : 'favorite';

                    const productData =
                        $product.data('product-unit-type') === UNIT_TYPE_SAMPLE
                            ? getDataForVariantId(productId)
                            : getProductObjectById(productId);

                    const product = new ProductModel(productData);

                    let productUrl = product.getWebUrl();

                    if (!productUrl && $product.hasClass('product')) {
                        productUrl = $product.find('.product-link').attr('href');
                    }

                    trackFavoritingEvent(action, this.getFavoriteTrackingLabel(), product);

                    // TODO: (CHAIR-9033) update favorite.js to have an element to trigger this event instead of document
                    const eventName = isDelete ? 'delete' : 'create';
                    $(document).trigger(`favorite.${eventName}`, {
                        $el: $product,
                        productId,
                    });
                }, this),
            )
            .fail(
                $.proxy(function () {
                    // if something happens serverside, remove the favorite.
                    // if the user was creating a new favorite it will be turned off.
                    // if the user was unfavoriting a product it will appear unfavorited.
                    this.removeProductId(productId, false);
                    this.drawButton(false, productId);

                    this._favoriteCount += isDelete ? 1 : -1;
                    this.updateFavoriteCount();
                }, this),
            );
    }
};

export default new FavoriteProductView();
