import $ from 'jquery';

import Modernizr from 'chairisher/util/modernizr';

const ChairishContext = window.chairisher.context;

export function isDesktopUserAgent() {
    return ChairishContext.IS_DESKTOP_USER_AGENT;
}

export function isMaxMediumDesktop() {
    return Modernizr.mq('(max-width: 991px)');
}

export function isMaxPhone() {
    return Modernizr.mq('(max-width: 480px)');
}

export function isMaxTablet() {
    return Modernizr.mq('(max-width: 767px)');
}

export function isMinMediumDesktop() {
    return Modernizr.mq('(min-width: 768px)');
}

export function isMinLargeDesktop() {
    return Modernizr.mq('(min-width: 992px)');
}

export function isTouchDevice() {
    return $('html').hasClass('touchevents');
}

export function matchesCurrentMedia(conditionString) {
    return conditionString ? Modernizr.mq(conditionString) : true;
}

export function prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}
