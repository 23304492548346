import $ from 'jquery';

import AppleLoader from 'chairisher/thirdparty/apple';
import ErrorContext from 'chairisher/context/error';
import FormFieldComponent from 'chairisher/component/form/field';
import GoogleAnalyticsUtils from 'chairisher/util/googleanalytics';

import { getAppleClientId, getAppleRedirectUri } from 'chairisher/context/apple';

export default {
    loadAuthButton() {
        const $appleButton = $('.js-btn-apple');
        const $loginOrCreateForm = $appleButton.closest('form');
        const $tosOptInField = $loginOrCreateForm.find('[name="tos_opt_in"]');

        AppleLoader.loadAuth().then((AppleAuth) => {
            AppleAuth.auth.init({
                clientId: getAppleClientId(),
                scope: 'name email',
                redirectURI: getAppleRedirectUri(),
                state: '',
                usePopup: true,
            });

            $appleButton.on('click', (e) => {
                e.preventDefault();

                if ($tosOptInField.length && !$tosOptInField.prop('disabled') && !$tosOptInField.prop('checked')) {
                    const name = 'tos_opt_in';

                    const tosField = new FormFieldComponent({
                        data: {},
                        $el: $tosOptInField,
                        name,
                    });

                    tosField.renderErrors([{ display: ErrorContext.getDefaultCheckboxError() }]);
                } else {
                    AppleAuth.auth
                        .signIn()
                        .then((data) => {
                            GoogleAnalyticsUtils.logEvent('Apple Sign In - Granted');
                            $loginOrCreateForm.find('[name=is_provider_initial_attempt]').val('1');
                            $loginOrCreateForm.find('[name=apple_auth_code]').val(data.authorization.code);
                            $loginOrCreateForm.find('[name=apple_id_token]').val(data.authorization.id_token);
                            $loginOrCreateForm.submit();
                        })
                        .catch(() => {
                            GoogleAnalyticsUtils.logEvent('Apple Sign In - Not Granted');
                        });
                }
            });
        });
    },
};
