const ChairishContext = window.chairisher.context;

/**
 * @returns {number} The number of products in the cart
 */
export function getCartCount() {
    return ChairishContext.CART_COUNT || 0;
}

/**
 * @returns {string} The URL to register for an account
 */
export function getCartRegisterUrl() {
    return ChairishContext.CART_REGISTER_URL;
}

/**
 * @returns {string} The URL for the cart
 */
export function getCartUrl() {
    return ChairishContext.CART_URL;
}
