const ChairishContext = window.chairisher.context;

/**
 * @returns {string|undefined} The recatptcha v3 key to our recaptcha site, which will be undefined on localhost
 */
export function getRecaptchaSiteKey() {
    return ChairishContext.RECAPTCHA_SITE_KEY;
}

/**
 * @returns {string|undefined} The string describing the recaptcha action
 */
export function getRecaptchaActionName() {
    return ChairishContext.RECAPTCHA_ACTION_NAME;
}

/**
 * @returns {object} The RecaptchaAction Enum from the backend
 */
export function getRecaptchaActionsObject() {
    return ChairishContext.RECAPTCHA_ACTIONS_JSON;
}

/**
 * @returns {string|undefined} The current user's buyer type code
 */
export function getBuyerTypeCode() {
    return ChairishContext.BUYER_TYPE_CODE;
}

/**
 * @return {string} The viewer's guid
 */
export function getGuid() {
    return ChairishContext.GUID;
}

/**
 * @param {string} guid
 */
export function setGuid(guid) {
    ChairishContext.GUID = guid;
}

/**
 * @returns {string} The URL for logging in
 */
export function getLoginUrl() {
    return ChairishContext.LOGIN_URL || '';
}

/**
 * @returns {string} The MD5 Hash of the GUID
 */
export function getMD5Guid() {
    return ChairishContext.MD5GUID || '';
}

/**
 * @param {string=} optTrackingCreateType Optional tracking create type to add as a GET param
 * @returns {string} The QuickCreate URL
 */
export function getQuickCreateUrl(optTrackingCreateType) {
    let quickCreateUrl = ChairishContext.QUICK_CREATE_URL || '';

    if (quickCreateUrl && optTrackingCreateType) {
        quickCreateUrl += `?v=${optTrackingCreateType}`;
    }

    return quickCreateUrl;
}

/**
 * @returns {Function|undefined} Optional function to call following successful auth of trade user
 */
export function getTradeAuthCallback() {
    return ChairishContext.tradeAuthCallback;
}

/**
 * @param {Function} tradeAuthCallback
 */
export function setTradeAuthCallback(tradeAuthCallback) {
    ChairishContext.tradeAuthCallback = tradeAuthCallback;
}

/**
 * @returns {boolean} True indicates the current user is a bot
 */
export function isBot() {
    return ChairishContext.IS_BOT;
}

/**
 * @returns {boolean} True indicates the current user is authenticated
 */
export function isAuthenticated() {
    return !!ChairishContext.IS_AUTHENTICATED;
}

/**
 * Checks whether the current user identity is known
 *
 * @returns {boolean} true if the current user identity is known; false otherwise.
 */
export function isIdentified() {
    const guid = getGuid();
    return guid && guid[0] !== '$';
}

/**
 * @returns {boolean} True indicates the current user can access the merch tool
 */
export function canAccessMerchTool() {
    return !!ChairishContext.CAN_ACCESS_MERCH_TOOL;
}

/**
 * @returns {boolean} True indicates the current user is staff
 */
export function isStaff() {
    return ChairishContext.IS_STAFF;
}

/**
 * @returns {boolean} True indicates the current user is impersonated
 */
export function isImpersonated() {
    return ChairishContext.IS_IMPERSONATED;
}

/**
 * @returns {boolean} True indicates the current user is a trade member
 */
export function isTrade() {
    return ChairishContext.IS_TRADE;
}

/**
 * @returns {Object} Object containing QuickCreate data
 */
export function getQuickCreateObject() {
    return ChairishContext.QUICK_CREATE;
}

/**
 * @returns {boolean} True indicates QuickCreate is initially sign up mode
 */
export function isQuickCreateSignUp() {
    if (getQuickCreateObject()) {
        return !!ChairishContext.QUICK_CREATE.IS_INITIAL_SIGNUP;
    }
    return false;
}

export function isTosappAgreementRequired() {
    return !!ChairishContext.IS_TOSAPP_AGREEMENT_REQUIRED;
}

/**
 * @returns {boolean}
 */
export function wasAccountCreated() {
    return !!ChairishContext.WAS_ACCOUNT_CREATED;
}
