const ChairishContext = window.chairisher.context;

/**
 * @returns {string}
 */
export function getAppleClientId() {
    return ChairishContext.APPLE_CLIENT_ID;
}

/**
 * @returns {string}
 */
export function getAppleRedirectUri() {
    return ChairishContext.APPLE_REDIRECT_URI;
}
