import $ from 'jquery';

import JqueryUtils from 'chairisher/util/jquery';

import { isMotdBannerVisible } from 'chairisher/context/site';

/**
 * Widget that presents choices as a menu that may have multiple levels of selection
 *
 * @param {Object} settings
 * @param {boolean=} settings.areOnlyLeafNodesSelectable @see this._areOnlyLeafNodesSelectable
 * @param {Array.<Choice>=} settings.choices @see this._choices
 * @param {boolean=} settings.isGlobalSearch @see this.isGlobalSearch
 * @param {boolean=} settings.isHidden True indicates the menu should be hidden by default
 * @param {boolean=} settings.isMultiSelect @see this._isMultiSelect
 * @param {string=} settings.menuChoiceClassName @see this._menuChoiceClassName
 * @param {Menu=} settings.parentMenu @see this._parentMenu
 * @param {string=} settings.menuSuggestionListClassName @see this._menuSuggestionListClassName
 * @param {string=} settings.selectedChoiceClassName @see this._selectedChoicesClassName
 * @param {Array.<Choice>=} settings.treeChoices @see this._treeChoices
 *
 * @constructor
 * @class Menu
 */
const Menu = function (settings) {
    settings = $.extend(
        {
            areOnlyLeafNodesSelectable: this._areOnlyLeafNodesSelectable,
            choices: [],
            dataDisplayKey: this._dataDisplayKey,
            isGlobalSearch: this.isGlobalSearch,
            isHidden: false,
            isMultiSelect: this._isMultiSelect,
            menuChoiceClassName: this._menuChoiceClassName,
            menuChoiceContentTemplateSelector: this._menuChoiceContentTemplateSelector,
            menuChoiceHasThumbnail: true,
            menuElClasses: this._menuElClass,
            menuSuggestionListClassName: this._menuSuggestionListClassName,
            parentMenu: this._parentMenu,
            selectedChoiceClassName: this._selectedChoiceClassName,
            treeChoices: [],
        },
        settings,
    );

    this._choices = settings.choices;
    this._treeChoices = settings.treeChoices;

    this._choiceIndextoSubmenuMap = {};
    this._parentMenu = settings.parentMenu;

    this._areOnlyLeafNodesSelectable = settings.areOnlyLeafNodesSelectable;
    this._dataDisplayKey = settings.dataDisplayKey;
    this.isGlobalSearch = settings.isGlobalSearch;
    this._menuChoiceClassName = settings.menuChoiceClassName;
    this._menuChoiceHasThumbnail = !!settings.menuChoiceHasThumbnail;
    this._menuElClass = settings.menuElClasses;
    this._menuSuggestionListClassName = settings.menuSuggestionListClassName;
    this._menuChoiceContentTemplateSelector = settings.menuChoiceContentTemplateSelector;
    this._selectedChoiceClassName = settings.selectedChoiceClassName;

    this._$menuChoiceContentTemplate = $($(this._menuChoiceContentTemplateSelector).html());

    this._initializeMenu(settings.isHidden);
    this.updateSuggestionChoices(this._choices);
    this.updateTreeChoices(this._treeChoices);
    this._bind();
};

/**
 * An object containing all tree choices at one level for easy lookup. Should be null everywhere except root menu
 *
 * @type {Object|null}
 * @private
 */
Menu.prototype._allTreeChoices = null;

/**
 * Flag that indicates if all nodes are selectable or just leaf nodes.
 * Only applicable to menu choices that contain boolean inputs
 *
 * @type {boolean}
 * @private
 */
Menu.prototype._areOnlyLeafNodesSelectable = false;

/**
 * A collection of Choice datastructures that are available as suggestions
 *
 * @type {Array.<Choice>}
 * @private
 */
Menu.prototype._choices = null;

/**
 * Maps the index of a menu choice to a submenu that can be displayed by pressing the right arrow
 *
 * @type {Object.<string, Menu>}
 * @private
 */
Menu.prototype._choiceIndextoSubmenuMap = null;

Menu.prototype._dataDisplayKey = 'display';

/**
 * Flag that indicates if menu is part of the global search
 *
 * @type {boolean}
 * @default
 */
Menu.prototype.isGlobalSearch = false;

/**
 * Flag that indicates if the choices are multi-select or not
 *
 * @type {boolean}
 * @private
 */
Menu.prototype._isMultiSelect = true;

/**
 * An instance of the current menu's parent menu, if any
 *
 * @type {Menu|null}
 * @private
 */
Menu.prototype._parentMenu = null;

/**
 * A class name used to decorate the currently selected choice
 *
 * @type {string}
 * @private
 */
Menu.prototype._selectedChoiceClassName = 'selected';

/**
 * Used to traverse the DOM to choose a selected choice
 *
 * @type {string}
 * @private
 */
Menu.prototype._selectedChoiceSelector = '.js-selected';

/**
 * Flag that indicates if menu choices should expect to display a thumbnail image or not
 *
 * @type {boolean}
 * @private
 */
Menu.prototype._menuChoiceHasThumbnail = true;

/**
 * Class used to style the `.js-menu` element; can be customized when constructing a new Menu
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuElClass = 'menu';

/**
 * Used to traverse the DOM to select a menu
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuElSelector = '.js-menu';

/**
 * Class used to style a `.js-menu-choice` element; can be customized when constructing a new Menu
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuChoiceClassName = 'menu-choice';

/**
 * Selector of the element that contains the template to use when marshaling menu choices
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuChoiceContentTemplateSelector = '#js-template-menu-choice-content';

/**
 * Used to traverse the DOM to select a menu choice
 *
 * @type {string}
 */
Menu.prototype.menuChoiceSelector = '.js-menu-choice';

/**
 * Class used to style a `.js-menu-suggestion-list` element; can be customized when constructing a new Menu
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuSuggestionListClassName = 'menu-suggestion-list';

/**
 * Used to traverse the DOM to select a menu suggestion list
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuSuggestionListSelector = '.js-menu-suggestion-list';

/**
 * Class used to style a `.js-menu-tree-list` element; can be customized when constructing a new Menu
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuTreeListClassName = 'menu-tree-list';

/**
 * Used to traverse the DOM to select a menu tree list
 *
 * @type {string}
 * @private
 */
Menu.prototype._menuTreeListSelector = '.js-menu-tree-list';

/**
 * A collection of Choice datastructures that are available as constant menu choices
 *
 * @type {Array.<Choice>}
 * @private
 */
Menu.prototype._treeChoices = null;

/**
 * The jQuery element that serves as a template for marshaling `.js-menu-choice-content` element
 *
 * @type {jQuery}
 * @private
 */
Menu.prototype._$menuChoiceContentTemplate = null;

/**
 * jQuery element representing the complete menu element
 *
 * @type {jQuery}
 * @private
 */
Menu.prototype._$menuEl = null;

/**
 * jQuery element that contains the list of suggested choices that come from the server
 *
 * @type {jQuery}
 * @private
 */
Menu.prototype._$menuSuggestionListEl = null;

/**
 * jQuery element that contains a list of choices organized in parent-child relationship
 *
 * @type {jQuery}
 * @private
 */
Menu.prototype._$menuTreeListEl = null;

/**
 * Removes the selected class from all menu items. Helpful for clients that only want to allow unique choices
 * especially when the enter key is pressed.
 */
Menu.prototype.clearAllSelections = function () {
    const $el = this.getRootMenu().getMenuEl().find(this._selectedChoiceSelector);
    $el.removeClass(this._selectedChoiceClassName);
    $el.removeClass(this._selectedChoiceSelector.substring(1));
};

/**
 * @param {jQuery=} opt_$choiceEl The `.js-menu-choice` element to deselect
 */
Menu.prototype.deselectChoiceEl = function (opt_$choiceEl) {
    let $choiceEl = opt_$choiceEl;
    if (!$choiceEl || !$choiceEl.length) {
        const $rootMenuEl = this.getRootMenu().getMenuEl();
        $choiceEl = $rootMenuEl.find(this._selectedChoiceSelector);
    }
    this._toggleSelection($choiceEl, false);
};

/**
 * Returns a Choice object for a given key if this menu is the root menu.
 *
 * @param {string} key The key representing the Choice that should be returned
 * @returns {Choice|undefined} The Choice object for the given key
 * @see this._allTreeChoices
 */
Menu.prototype.getTreeChoiceForKey = function (key) {
    if ($.isPlainObject(this._allTreeChoices)) {
        return this._allTreeChoices[key];
    }
};

/**
 * @returns {Array.<Choice>}
 */
Menu.prototype.getSuggestionChoices = function () {
    return this._choices;
};

/**
 * @returns {Array.<Choice>}
 */
Menu.prototype.getTreeChoices = function () {
    return this._treeChoices;
};

/**
 * @returns {Array.<jQuery>} a collection of this Menu's `.js-menu-choice` elements
 */
Menu.prototype.getChoiceEls = function () {
    return this._$menuSuggestionListEl.children(this.menuChoiceSelector);
};

/**
 * @param {jQuery} $el The element whose index should be retrieved
 * @returns {number} The element's index
 */
Menu.prototype.getIndexForEl = function ($el) {
    return this.isGlobalSearch ? $el.index(this.menuChoiceSelector) : $el.index();
};

/**
 * @returns {Array.<jQuery>} a collection of this Menu's `.js-selected` elements
 */
Menu.prototype.getSelectedChoiceEl = function () {
    return this.getChoiceEls().filter(this._selectedChoiceSelector);
};

/**
 * @param {number} choiceIndex The zero-based index indicating which `.js-menu-choice` to select
 * @param {boolean=} opt_shouldFocus True indicates selection should be made via :focus
 */
Menu.prototype.makeSelection = function (choiceIndex, opt_shouldFocus) {
    choiceIndex = this.getNextIndex(choiceIndex);
    const $choice = this.getChoiceEls().eq(choiceIndex);

    if ($choice.length) {
        if (opt_shouldFocus) {
            // once we trigger :focus on the new selection, :blur will automatically be triggered,
            // removing the selected state from the currently selected index ($selected)...
            $choice.trigger('focus');
        } else {
            this.selectChoiceEl($choice);
        }
    }
};

/**
 * @returns {jQuery} This Menu instance's `.js-menu` element
 */
Menu.prototype.getMenuEl = function () {
    return this._$menuEl;
};

/**
 * @param {number|undefined} desiredIndex The index that is attempting to be used
 * @returns {number} The final index to use
 */
Menu.prototype.getNextIndex = function (desiredIndex) {
    let index = desiredIndex;
    const choicesLength = this._choices.length;

    if (desiredIndex === undefined) {
        index = 0;
    } else if (desiredIndex > choicesLength - 1) {
        index = 0;
    } else if (desiredIndex < 0) {
        index = choicesLength - 1;
    }

    return index;
};

/**
 * @returns {Menu} The top most Menu in a collection of Menus
 */
Menu.prototype.getRootMenu = function () {
    let rootMenu = this;

    if (rootMenu._parentMenu) {
        rootMenu = this._parentMenu.getRootMenu();
    }

    return rootMenu;
};

/**
 * Hides the Menu
 */
Menu.prototype.hide = function () {
    this.toggle(false);
};

/**
 * @returns {boolean} True indicates this menu is visible
 */
Menu.prototype.isVisible = function () {
    return !this._$menuEl.hasClass('hidden');
};

/**
 * Removes the menu item choice at a given index from the ui and data
 *
 * @param {number} index The 0-based index indicating which item should be removed
 */
Menu.prototype.removeMenuEl = function (index) {
    this._$menuSuggestionListEl.find(this.menuChoiceSelector).eq(index).remove();
    this._choices.splice(index);
};

/**
 * @param {jQuery} $choiceEl The `.js-menu-choice` element to select
 */
Menu.prototype.selectChoiceEl = function ($choiceEl) {
    this._toggleSelection($choiceEl, true);
};

/**
 * Shows the Menu
 */
Menu.prototype.show = function () {
    this.toggle(true);
};

/**
 * Toggles the Menu
 *
 * @param {boolean=} opt_isVisible True indicate the Menu should be visible, False hidden, and undefined toggles
 */
Menu.prototype.toggle = function (opt_isVisible) {
    const isVisible = opt_isVisible !== undefined ? !opt_isVisible : undefined;
    this._$menuEl.toggleClass('hidden', isVisible);
};

/**
 * Updates the suggested choices and re-renders them
 *
 * @param {Array.<Choice>} choices The array choices to set
 */
Menu.prototype.updateSuggestionChoices = function (choices) {
    if ($.isArray(choices)) {
        this._choices = choices;
        this._$menuSuggestionListEl.html(this._marshalMenuList(choices, this._dataDisplayKey).html());
    }
};

/**
 * Updates the tree choices and re-renders them
 *
 * @param {Array.<Choice>} choices The array choices to set
 */
Menu.prototype.updateTreeChoices = function (choices) {
    if ($.isArray(choices)) {
        this._treeChoices = choices;

        // update the flattened mapping of choice value to Choice object in the root menu...
        const choiceKeyToChoiceMap = {};
        for (let i = 0; i < this._treeChoices.length; i++) {
            const choice = this._treeChoices[i];
            choiceKeyToChoiceMap[choice.value] = choice;
        }
        const rootMenu = this.getRootMenu();
        const allTreeChoices = rootMenu._allTreeChoices;
        rootMenu._allTreeChoices = $.extend(allTreeChoices, choiceKeyToChoiceMap);

        // Note: you *must* use .children() and *cannot* use .html() because the
        // reference to the DOM fragments will be lost...
        const $menuTreeListEl = this._$menuTreeListEl;
        $menuTreeListEl.html(this._marshalMenuList(choices).children());

        const $menuChoices = $menuTreeListEl.children(this.menuChoiceSelector);

        // call .off() before .on() to ensure the choices are never double bound...
        $menuChoices.off('click').on(
            'click',
            $.proxy(function (e) {
                e.stopPropagation();
                const $target = $(e.currentTarget);

                const submenu = this._choiceIndextoSubmenuMap[$target.index()];
                if (submenu) {
                    const isVisible = submenu.isVisible();

                    const $topMenu = $target.closest(this._menuElSelector);
                    $topMenu.find(this._menuElSelector).addClass('hidden');

                    $target.toggleClass('expanded', !isVisible);

                    if (!isVisible) {
                        submenu.show();
                    }

                    const $expandedMenuChoices = this.getRootMenu().getMenuEl().find('.expanded');
                    $.each($expandedMenuChoices, (i) => {
                        const $choice = $expandedMenuChoices.eq(i);
                        $choice.toggleClass('expanded', !$choice.children('.js-menu').hasClass('hidden'));
                    });
                }
            }, this),
        );
    }
};

/**
 * Binds all necessary events to make the Menu work
 *
 * @private
 */
Menu.prototype._bind = function () {
    const $menuEl = this.getMenuEl();

    const getValueFromTarget = $.proxy(function (target) {
        let $el = $(target);

        if (!$el.hasClass(this._menuChoiceClassName)) {
            $el = $el.closest(this.menuChoiceSelector);
        }

        const index = this.getIndexForEl($el);
        let selectedChoice;

        if ($el.parent(this._menuTreeListSelector).length) {
            selectedChoice = this.getTreeChoices()[index];
        } else {
            selectedChoice = this.getSuggestionChoices()[index];
        }

        selectedChoice.submenu = this._choiceIndextoSubmenuMap[index];

        return selectedChoice;
    }, this);

    $menuEl.on(
        'mousedown',
        '.js-boolean-input',
        $.proxy((e) => {
            e.preventDefault();
            e.stopPropagation();

            $menuEl.trigger('input:selected', getValueFromTarget(e.currentTarget));
        }, this),
    );

    $menuEl.on(
        'mousedown',
        this.menuChoiceSelector,
        $.proxy((e) => {
            // clicking on a menu choice results in a :focus event followed by a :blur event;
            e.preventDefault(); // preventing default cancels out the :blur event
            e.stopPropagation(); // when clicking in a submenu prevent the click from bubbling to the parent menu...

            $menuEl.trigger('menuchoice:selected', getValueFromTarget(e.currentTarget));
        }, this),
    );
};

/**
 * Builds the initial menu structure
 *
 * @param {boolean=} opt_isHidden True indicates the menu should be hidden by default
 * @private
 */
Menu.prototype._initializeMenu = function (opt_isHidden) {
    const $menuEl = $('<div></div>', { class: this._menuElClass });
    JqueryUtils.decorateElementFromSelector($menuEl, this._menuElSelector);

    if (isMotdBannerVisible()) {
        $menuEl.addClass('banner-visible');
    }

    if (opt_isHidden === true) {
        $menuEl.addClass('hidden');
    }

    const $menuSuggestionListEl = $('<ul></ul>', { class: this._menuSuggestionListClassName });
    JqueryUtils.decorateElementFromSelector($menuSuggestionListEl, this._menuSuggestionListSelector);

    const $menuTreeListEl = $('<ul></ul>', { class: this._menuTreeListClassName });
    JqueryUtils.decorateElementFromSelector($menuTreeListEl, this._menuTreeListSelector);

    $menuEl.append($menuSuggestionListEl, $menuTreeListEl);

    this._$menuEl = $menuEl;
    this._$menuSuggestionListEl = $menuSuggestionListEl;
    this._$menuTreeListEl = $menuTreeListEl;
};

/**
 * Creates a new menu list comprised of a given collection of Choice datastructures
 *
 * @param {Array.<Choice>} choices The array choices to render in the list
 * @param {string=} opt_displayKey Optional key to use to choose display text; defaults to `display`
 * @returns {jQuery} The newly created menu list
 * @private
 */
Menu.prototype._marshalMenuList = function (choices, opt_displayKey) {
    const $menuList = $('<ul></ul>');

    // only used for global searches
    const makeMenuHeading = (displayName) => $(`<li class='menu-heading' tabindex="-1">${displayName}</li>`);
    const menuChoicesGeneral = [];
    const menuChoicesMaker = [];
    const menuChoicesShop = [];

    for (let i = 0; i < choices.length; i++) {
        const choice = choices[i];

        const $menuChoice = $('<li></li>', {
            class: this._menuChoiceClassName,
            tabindex: 0,
        });
        JqueryUtils.decorateElementFromSelector($menuChoice, this.menuChoiceSelector);

        const $content = $(this._$menuChoiceContentTemplate[0].outerHTML);

        const $text = $content.find('.js-menu-choice-text');
        let choiceDisplay = choice[opt_displayKey || 'display'];

        if (this.isGlobalSearch && choice.is_shop && choice.is_official_shop) {
            choiceDisplay += '<strong class="official-shop-badge">Official Shop</strong>';
        }

        $text.html(choiceDisplay);

        //
        // Add extra additional subtext to menu item
        //

        if (choice.additional_display) {
            const $additionalDisplay = $('<label />', {
                class: 'js-additional-menu-choice-text additional-menu-choice-text',
            });
            $additionalDisplay.text(choice.additional_display);
            $content.append($additionalDisplay);
            $content.addClass('menu-choice-column');
        }

        //
        // Add a URL to the choice if available for use in downstream simple dropdown menu clients
        //

        if (choice.url) {
            $content.attr('data-href', choice.url);
        }

        //
        // Draw the choice image if available
        //

        if (this._menuChoiceHasThumbnail) {
            if (choice.image) {
                const $img = $('<img />', {
                    alt: `Image of ${choice.display}`,
                    src: choice.image.src,
                    srcset: choice.image.srcset,
                    sizes: choice.image.sizes,
                });
                $content.find('.js-menu-choice-image').html($img);
            }
        } else {
            $content.find('.js-menu-choice-image').remove();
        }

        //
        // Set the appropriate values in the boolean input if available
        //

        const $input = $content.find('input');
        if ($input.length) {
            $input.attr({
                value: choice.value,
                type: this._isMultiSelect ? 'checkbox' : 'radio',
            });
        }

        $menuChoice.append($content);

        if (choice.children) {
            $text.append(
                $('<span></span>', {
                    class: 'cicon cicon-plussign',
                }),
            );

            $text.append(
                $('<span></span>', {
                    class: 'cicon cicon-minussign',
                }),
            );

            // todo: (CHAIR-5561) circle back and make this less brittle...
            if (this._areOnlyLeafNodesSelectable && $input.length) {
                $input.closest('.js-boolean-input').css('opacity', 0).removeClass('js-boolean-input');
                $input.remove();
            }

            const subMenu = new Menu({
                areOnlyLeafNodesSelectable: this._areOnlyLeafNodesSelectable,
                isHidden: true,
                menuChoiceContentTemplateSelector: this._menuChoiceContentTemplateSelector,
                menuChoiceHasThumbnail: this._menuChoiceHasThumbnail,
                parentMenu: this,
                treeChoices: choice.children,
            });
            $menuChoice.append(subMenu.getMenuEl());
            this._choiceIndextoSubmenuMap[i] = subMenu;
        }

        if (this.isGlobalSearch) {
            // sorts the choices into specific lists based on whether the choice is a maker, shop or neither
            if (choice.is_maker) {
                menuChoicesMaker.push($menuChoice);
            } else if (choice.is_shop) {
                menuChoicesShop.push($menuChoice);
            } else {
                menuChoicesGeneral.push($menuChoice);
            }
        } else {
            $menuList.append($menuChoice);
        }
    }

    if (this.isGlobalSearch) {
        // appends the choices lists in order - general (non-maker / non-shop) first, then maker and shop
        // this order is important and matches the response returned by the search_lookup_view
        $menuList.append(menuChoicesGeneral);
        if (menuChoicesMaker.length > 0) {
            menuChoicesMaker.unshift(makeMenuHeading('Brands & Creators'));
            $menuList.append(menuChoicesMaker);
        }
        if (menuChoicesShop.length > 0) {
            menuChoicesShop.unshift(makeMenuHeading('Shops'));
            $menuList.append(menuChoicesShop);
        }
    }

    return $menuList;
};

/**
 * Toggles classes for an element that make it selected or not
 *
 * @param {jQuery} $el The element whose classes should be toggled
 * @param {boolean=} opt_isSelected Optional flag that indicates if the classes should be removed or not
 * @private
 */
Menu.prototype._toggleSelection = function ($el, opt_isSelected) {
    $el.toggleClass(
        [this._selectedChoiceClassName, this._selectedChoiceSelector.replace('.', '')].join(' '),
        opt_isSelected,
    );
};

export default Menu;
