const ChairishContext = window.chairisher.context;

/**
 * @returns {string} The name of the url from django's router.
 */
export function getUrlName() {
    return ChairishContext.URL_NAME;
}

/**
 * @return {boolean} An indication of whether amplitude events should be printed to console.
 */
export function shouldDebugAmplitude() {
    return !!ChairishContext.SHOULD_DEBUG_AMPLITUDE;
}

/**
 * @returns {string} The GA4 tag id for the current environment
 */
export function getGa4TagId() {
    return ChairishContext.GA4_TAG_ID;
}

/**
 * @returns {number} The number of pixels the viewport has been scrolled
 */
export function getScrollY() {
    return ChairishContext.SCROLL_Y;
}

/**
 * @returns {number} Number of re-Chairished items, for display in the UHP's sustainability module
 */
export function getSustainabilityKpi() {
    return ChairishContext.sustainabilityKpi;
}

/**
 * @returns {boolean} True indicates the live chat widget will show
 */
export function isLiveChatActive() {
    return !!ChairishContext.LIVECHAT_IS_ACTIVE;
}

/**
 * @returns {boolean} True if the MOTD Banner has copy and, therefore, will be displayed
 */
export function isMotdBannerVisible() {
    return ChairishContext.IS_MOTD_BANNER_VISIBLE;
}

/**
 * @param {boolean} isActive True indicates the zendesk livechat will be set to active; otherwise, false
 */
export function setLiveChatIsActive(isActive) {
    ChairishContext.LIVECHAT_IS_ACTIVE = !!isActive;
}

/**
 * @param {number} scrollY The number of pixels the viewport has been scrolled
 */
export function setScrollY(scrollY) {
    ChairishContext.SCROLL_Y = scrollY;
}
