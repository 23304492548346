import { AmplitudeEventProperty, logAmplitudeButtonClickEvent, logAmplitudeEvent } from 'chairisher/util/analytics';
import { isTrade } from 'chairisher/context/auth';
import { isLiveChatActive } from 'chairisher/context/site';

/**
 * Tracks when the zendesk web widget opens
 *
 * @param {string=} trackingPosition Optional tracking position to include with event
 */
export function trackStartChatSession(trackingPosition) {
    if (isLiveChatActive()) {
        window.zE('webWidget:on', 'open', () => {
            const eventProps = {};
            if (isTrade()) {
                eventProps[AmplitudeEventProperty.USER_TYPE] = 'Trade';
            }
            if (trackingPosition) {
                eventProps[AmplitudeEventProperty.POSITION] = trackingPosition;
            }
            logAmplitudeButtonClickEvent('Open Zendesk Widget', eventProps);
        });
    }
}

/**
 * Binds zendesk web widget event tracking
 *
 * @param {string=} trackingPosition Optional tracking position to include with events
 * @see https://developer.zendesk.com/api-reference/widget/core/#on-userevent
 */
export function bindTrackUserEvents(trackingPosition) {
    if (isLiveChatActive()) {
        window.zE('webWidget:on', 'userEvent', (event) => {
            const eventProps = {
                [AmplitudeEventProperty.ZE_ACTION]: event.action,
                [AmplitudeEventProperty.ZE_CATEGORY]: event.category,
            };
            if (isTrade()) {
                eventProps[AmplitudeEventProperty.USER_TYPE] = 'Trade';
            }
            if (trackingPosition) {
                eventProps[AmplitudeEventProperty.POSITION] = trackingPosition;
            }
            logAmplitudeEvent('zendesk widget', eventProps);
        });
    }
}
