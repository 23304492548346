import $ from 'jquery';
import ModalFactory from 'chairisher/factory/modal';

import { logAmplitudeEvent } from 'chairisher/util/analytics';
import { isTosappAgreementRequired } from 'chairisher/context/auth';

/**
 * @constructor
 * @class TermsOfServiceAndPrivacyPolicyView
 */
const TermsOfServiceAndPrivacyPolicyView = function () {};

/**
 * The selector for the tosapp banner form container
 *
 * @type {string}
 * @private
 * @default
 */
TermsOfServiceAndPrivacyPolicyView.prototype._bannerFormContainerSelector = '#js-tosapp-banner-form-container';

/**
 * The DOM ID for the tosapp modal
 *
 * @type {string}
 * @private
 * @default
 */
TermsOfServiceAndPrivacyPolicyView.prototype._modalId = 'js-tosapp-modal';

/**
 * Shows the banner
 */
TermsOfServiceAndPrivacyPolicyView.prototype.showBanner = function () {
    if (isTosappAgreementRequired()) {
        const $formContainer = $(this._bannerFormContainerSelector);
        const $form = $formContainer.find('form');
        const $agreeButton = $form.find('.js-tosapp-agree');

        $form.on('submit', (e) => {
            e.preventDefault();
            $agreeButton.prop('disabled', true);

            $.ajax({
                data: $form.serialize(),
                method: 'POST',
                url: $form.attr('action'),
            })
                .done(() => {
                    $formContainer.addClass('hidden');
                    logAmplitudeEvent('tosapp - agree');
                })
                .fail(() => {
                    $agreeButton.prop('disabled', false);
                });
        });

        $formContainer.removeClass('hidden');
    }
};

/**
 * Shows the modal
 *
 * @param {boolean} shouldCancelRedirect Whether clicking cancel should redirect to the root page
 */
TermsOfServiceAndPrivacyPolicyView.prototype.showModal = function (shouldCancelRedirect) {
    const $deferred = $.Deferred();
    if (isTosappAgreementRequired()) {
        const $modal = ModalFactory.createModal(this._modalId);
        const $modalContent = $('#js-tosapp-modal-template');
        $modal.find('.modal-body').html($modalContent.html());
        $modal.find('.js-modal-header').remove();

        $modal.appendTo(document.body);

        // assign this button after setting the modal body above
        const $agreeButton = $modal.find('.js-tosapp-agree');

        $modal.on(
            'submit',
            'form',
            $.proxy(function (e) {
                e.preventDefault();
                $agreeButton.prop('disabled', true);

                const $form = $modal.find('form');
                $.ajax({
                    data: $form.serialize(),
                    method: 'POST',
                    url: $form.attr('action'),
                })
                    .done(
                        $.proxy(function () {
                            $modal.modal('hide');
                            logAmplitudeEvent('tosapp - agree');

                            $(this._bannerFormContainerSelector).addClass('hidden');

                            $deferred.resolve();
                        }, this),
                    )
                    .fail(() => {
                        $agreeButton.prop('disabled', false);
                    });
            }, this),
        );

        $modal.on('click', '.js-tosapp-cancel', (e) => {
            e.preventDefault();
            $modal.modal('hide');
            logAmplitudeEvent('tosapp - disagree');

            if (shouldCancelRedirect) {
                window.location.href = '/';
            }
            $deferred.resolve();
        });

        $modal.on('hidden.bs.modal', () => {
            $modal.remove();
        });

        $modal.on('shown.bs.modal', () => {
            logAmplitudeEvent('screen - agree tosapp');
        });

        $modal.modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
        });
    } else {
        $deferred.resolve();
    }

    return $deferred;
};

export default TermsOfServiceAndPrivacyPolicyView;
