import CookieUtils from 'chairisher/util/cookie';
import UriUtils from 'chairisher/util/uri';

import { isIdentified } from 'chairisher/context/auth';
import { canLoadFunctionalCookies } from 'chairisher/context/cookies';

const MEMBER_COOKIE = 'MCEvilPopupClosed';
const MEMBER_COOKIE_VALUE = 1;
const MEMBER_COOKIE_DURATION_IN_DAYS = 14;

export function setEmailModalOptOutCookie() {
    if (canLoadFunctionalCookies()) {
        CookieUtils.setCookie(MEMBER_COOKIE, MEMBER_COOKIE_VALUE, MEMBER_COOKIE_DURATION_IN_DAYS);
    }
}

/**
 * @param {string} path The path to test for eligibility
 * @returns {boolean} True indicates the path is eligible to have marketing promos displayed
 */
function isPathEligibleForMarketingPromo(path) {
    const blacklistedPaths = [
        '^/$',
        '^/account/*',
        '^/app',
        '^/cart',
        '^/email/create/*',
        '^/pages/*',
        '^/product$',
        '^/product/create/*',
        '/offer/create$',
        '^/question/*',
        '^/redirect/*',
        '^/trade',
    ];
    const re = new RegExp(blacklistedPaths.join('|'), 'i');
    return re.test(path) === false;
}

/**
 * @returns {boolean} True indicates the web page viewer is already opted out of the mailing list
 */
function isViewerMemberAlready() {
    return CookieUtils.getIntValueFromCookie(MEMBER_COOKIE) === MEMBER_COOKIE_VALUE;
}

/**
 * Determines if the email collection form should be displayed.
 * Factors considered are user authentication state, page uri, and if the viewer is already a signed up.
 *
 * Per Google, we are allowed to display the email modal to visitors originating from Google PLAs, but only on
 * subsequent page views; not the page they land on immediately after clicking an ad.
 *
 * @returns {boolean}
 */
export function shouldDisplayEmailMarketingModal() {
    if (isIdentified()) {
        setEmailModalOptOutCookie();
        return false;
    }

    return (
        !UriUtils.uriOriginatesFromGoogle(document.referrer) &&
        !UriUtils.uriOriginatesFromGooglePLA() &&
        !UriUtils.uriOriginatesFromBingPLA() &&
        !UriUtils.uriOriginatesFromInstagramStory() &&
        !UriUtils.uriOriginatesFromMarketing() &&
        !UriUtils.uriOriginatesFromSMS() &&
        !UriUtils.uriOriginatesFromSellerBadge(document.location.search) &&
        isPathEligibleForMarketingPromo(window.location.pathname) &&
        !isViewerMemberAlready()
    );
}
