import $ from 'jquery';

import ChairishABTest from 'chairisher/abtest';

import { isMinLargeDesktop } from 'chairisher/util/mediaquery';

// TODO: (CHAIR-17290) - Delete file after ab test
export default class MessageSellerCtaTest extends ChairishABTest {
    static getExperimentName() {
        return 'MESSAGE_SELLER_CTA_TEST';
    }

    variation() {
        // hides cta link + displays cta button in seller-info section
        $('.js-message-seller-cta-control').addClass('hidden');
        $('.js-message-seller-cta-variant').removeClass('hidden');

        // rearranges info returns and info questions sections, if desktop
        if (isMinLargeDesktop()) {
            $('.js-message-seller-cta-section-control').addClass('hidden');
            $('.js-message-seller-cta-section-variant').removeClass('hidden');
        }

        // increases size of button in info-questions-section
        $('.js-message-user-form').addClass('message-user-form-variant');
    }
}
