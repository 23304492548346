import AmplitudeLibrary from 'amplitude';

import { isBot, isImpersonated, isStaff } from 'chairisher/context/auth';
import { getAmplitudeApiKey } from 'chairisher/context/tracking';

/**
 * Loads the Amplitude JS SDK.
 */
export default function initializeAmplitude() {
    if (!isImpersonated() && !isStaff() && !isBot()) {
        const amplitudeApiKey = getAmplitudeApiKey();

        if (amplitudeApiKey) {
            try {
                AmplitudeLibrary.init(amplitudeApiKey, null, {
                    includeGclid: true,
                    includeReferrer: true,
                    includeUtm: true,
                    saveParamsReferrerOncePerSession: false,
                    unsetParamsReferrerOnNewSession: true,
                });

                return AmplitudeLibrary;
            } catch (e) {
                console.error('failed to initialize amplitude:', e);
            }
        }
    }

    return null;
}
