import CookieUtils from 'chairisher/util/cookie';

const ChairishContext = window.chairisher.context;

/**
 * @returns {string} Opt out value
 */
export function getOptOutOfCookiesValue() {
    return ChairishContext.OPT_OUT_OF_COOKIES_VALUE;
}

/**
 * @param {string} cookieCode
 * @returns {boolean} Whether the user is opted out of the cookie
 */
export function isOptedOutOfCookie(cookieCode) {
    return CookieUtils.getValueFromCookie(cookieCode) === getOptOutOfCookiesValue();
}

/**
 * @returns {boolean} True indicates advertising cookies / pixels can be loaded
 */
export function canLoadAdvertisingCookies() {
    return !isOptedOutOfCookie(ChairishContext.ADVERTISING_COOKIES_CODE);
}

/**
 * @returns {boolean} True indicates functional cookies / pixels can be loaded
 */
export function canLoadFunctionalCookies() {
    return !isOptedOutOfCookie(ChairishContext.FUNCTIONAL_COOKIES_CODE);
}

/**
 * @returns {boolean} True indicates performance cookies / pixels can be loaded
 */
export function canLoadPerformanceCookies() {
    return !isOptedOutOfCookie(ChairishContext.PERFORMANCE_COOKIES_CODE);
}

/**
 * @returns {string} Cookies preference code
 */
export function getCookiesPreferenceCode() {
    return ChairishContext.COOKIES_PREFERENCES_CODE;
}

/**
 * @returns {string} Advertising cookie code
 */
export function getAdvertisingCookiesCode() {
    return ChairishContext.ADVERTISING_COOKIES_CODE;
}

/**
 * @returns {string} Functional cookie code
 */
export function getFunctionalCookiesCode() {
    return ChairishContext.FUNCTIONAL_COOKIES_CODE;
}

/**
 * @returns {string} Performance cookie code
 */
export function getPerformanceCookiesCode() {
    return ChairishContext.PERFORMANCE_COOKIES_CODE;
}

/**
 * @returns {string} Cookie Banner Code
 */
export function getCookieBannerCode() {
    return ChairishContext.COOKIE_BANNER_CODE;
}

/**
 * @param {string} cookieCode The cookie code to set whether the user is opted in/out of
 * @param {boolean} isOptedOut
 */
export function setIsOptedOutOfCookie(cookieCode, isOptedOut) {
    if (isOptedOut) {
        CookieUtils.setCookie(cookieCode, getOptOutOfCookiesValue());
    } else {
        CookieUtils.removeCookie(cookieCode);
    }
}
