import { getAppleAuthJSUrl } from 'chairisher/context/script';

let applePromise;

// **NOTE**: This file is separate impl of the apple loader for webpack only.
//           It overwrites apple.js at compile time. Any logic changes should be duplicated for now.
// TODO (CHAIR-15663): Delete this file after migration is complete.

/**
 * @returns {Promise}
 */
function loadAuth() {
    if (applePromise) {
        return applePromise;
    }
    applePromise = new Promise((resolve, reject) => {
        const scriptSrc = getAppleAuthJSUrl();
        const rejectWrapper = () => {
            reject(new Error(`failed to load script "${scriptSrc}"`));
        };

        const script = document.createElement('script');
        script.async = true;
        script.onerror = rejectWrapper;
        script.src = scriptSrc;
        script.onload = () => {
            if (window.AppleID) {
                resolve(window.AppleID);
            } else {
                rejectWrapper();
            }
        };
        document.head.appendChild(script);
    });

    return applePromise;
}

export default { loadAuth };
