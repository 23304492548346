import $ from 'jquery';

import ModalFactory from 'chairisher/factory/modal';

import executeRecaptcha from 'chairisher/view/helper/recaptcha';

import {
    getTradeAuthCallback,
    getQuickCreateObject,
    getRecaptchaActionName,
    isQuickCreateSignUp,
} from 'chairisher/context/auth';

/**
 * Manages the view layer for asynchronous authentication
 *
 * @param {Object=} opt_attrs
 * @param {string=} opt_attrs.formSelector The selector of the form contained by the modal. Default is `#js-chairish-authentication-modal-form`
 * @param {string=} opt_attrs.modalSelector The selector of the modal to insert the HTML form into. Default is `#js-chairish-authentication-modal`
 * @param {boolean=} opt_attrs.shouldBeDismissible Whether the modal should be dismissible
 *
 * @constructor
 */
const AuthenticationView = function (opt_attrs) {
    opt_attrs = $.extend(
        {
            formSelector: this._formSelector,
            modalSelector: this._modalSelector,
            shouldBeDismissible: this._shouldBeDismissible,
        },
        opt_attrs,
    );

    this._formSelector = opt_attrs.formSelector;
    this._modalSelector = opt_attrs.modalSelector;
    this._shouldBeDismissible = opt_attrs.shouldBeDismissible;
};

/**
 * @type {boolean}
 * @private
 */
AuthenticationView.prototype._didAttemptAuthentication = false;

/**
 * @type {string} The jQuery selector for the authentication form modal
 * @private
 */
AuthenticationView.prototype._modalSelector = '#js-chairish-authentication-modal';

/**
 * @type {string} The jQuery selector for the authentication form modal form
 * @private
 */
AuthenticationView.prototype._formSelector = '#js-chairish-authentication-modal-form';

/**
 * @type {boolean} True indicates the modal is displaying the sign up copy; displays log in copy when false
 * @private
 */
AuthenticationView.prototype._isSignUpMode = false;

/**
 * Indication of whether the modal should be dismissible
 *
 * @type {boolean}
 * @private
 * @default
 */
AuthenticationView.prototype._shouldBeDismissible = true;

/**
 * @returns {boolean}
 */
AuthenticationView.prototype.didAttemptAuthentication = function () {
    return this._didAttemptAuthentication;
};

/**
 * @returns {string}
 */
AuthenticationView.prototype.getModalSelector = function () {
    return this._modalSelector;
};

/**
 * @returns {boolean} Indicates if the email collection modal should be dismissible
 */
AuthenticationView.prototype.shouldBeDismissible = function () {
    return this._shouldBeDismissible;
};

/**
 * @param {boolean} didAttemptAuthentication True indicates authentication was attempted
 */
AuthenticationView.prototype.setDidAttemptAuthentication = function (didAttemptAuthentication) {
    this._didAttemptAuthentication = didAttemptAuthentication;
};

/**
 * Displays the account quick create form allowing people to log in or create an account.
 *
 * @param {Object} attrs - The attributes to use to display the authentication form
 * @param {string} attrs.classes - A space separated list of classes to append to the modal
 * @param {string} attrs.url - The URL of authentication form to display
 *
 * @returns {jqXHR}
 */
AuthenticationView.prototype.display = function (attrs) {
    return $.ajax({ url: attrs.url }).done((data) => {
        const $modal = ModalFactory.createModal(this.getModalSelector().replace('#', ''));
        $modal.addClass(attrs.classes);
        $modal.appendTo(document.body);

        this.setFormHtml(data.authentication_form_html);

        $modal.on('hidden.bs.modal', function () {
            $(this).remove();
        });

        if (this.shouldBeDismissible()) {
            $modal.modal({
                backdrop: true,
                keyboard: true,
                show: false,
            });
        } else {
            const $modalHeader = $modal.find('.js-modal-header');
            $modalHeader.remove();
            $modal.modal({
                backdrop: 'static',
                keyboard: false,
                show: false,
            });
        }

        $modal.modal('show');

        executeRecaptcha(getRecaptchaActionName() || 'quickcreate');
    });
};

/**
 * Hides the account quick create form.
 */
AuthenticationView.prototype.hide = function () {
    $(this.getModalSelector()).modal('hide');
};

/**
 * @param {string} html The HTML to inject into the modal body
 */
AuthenticationView.prototype.setFormHtml = function (html) {
    const $modal = $(this.getModalSelector());
    $modal.find('.modal-body').html(html);
    $modal.find('form').buttonLoadingHelper();

    this._isSignUpMode = isQuickCreateSignUp();

    // sign-in mode should not require terms of service agreement
    $modal.find('[name="tos_opt_in"]').prop('disabled', !this._isSignUpMode);

    $modal.find('.js-auth-toggle').on(
        'click',
        'a',
        $.proxy(function (e) {
            e.preventDefault();
            this.toggleSignUpMode();
        }, this),
    );
};

/**
 * Authenticates an account by sending its username and password to the server
 *
 * @param {string} endpoint The URL to submit the form to
 * @param {string} dataString Maps username and password fields
 * @param {Function(Object)=} opt_successCallback Optional callback to execute on success
 * @param {Function(Object)=} opt_errorCallback Optional callback to execute on error
 */
AuthenticationView.prototype.submit = function (endpoint, dataString, opt_successCallback, opt_errorCallback) {
    const callback = function (data) {
        if (data.num_cart_items) {
            $('#js-cart-count').text(data.num_cart_items || '');
        }
        if (data.my_account_nav_html) {
            $('.js-account-nav').html(data.my_account_nav_html);
        }
        if (data.favorites_nav_html) {
            $('.js-favorites-nav-menu').html(data.favorites_nav_html);
        }
        if (data.is_trade) {
            const tradeAuthCallback = getTradeAuthCallback();
            if (tradeAuthCallback) {
                tradeAuthCallback();
            }
        }
    };

    return $.ajax({
        data: `${dataString}&is_sign_up=${this._isSignUpMode}`,
        method: 'POST',
        url: endpoint,
    })
        .done((data, textStatus, jqXHR) => {
            const callbackData = $.extend({ status: jqXHR.status }, data);
            callback(callbackData);

            if ($.isFunction(opt_successCallback)) {
                opt_successCallback(callbackData);
            }
        })
        .fail((jqXHR) => {
            const callbackData = $.extend({}, jqXHR.responseJSON, { status: jqXHR.status });
            callback(callbackData);

            if ($.isFunction(opt_errorCallback)) {
                opt_errorCallback(callbackData);
            }
            $(this._formSelector).resetLoadingButtons();
            executeRecaptcha(getRecaptchaActionName() || 'quickcreate');
        })
        .always(() => {
            this.setDidAttemptAuthentication(true);
        });
};

/**
 * Toggles the content of the modal to display either the appropriate "Log in" or "Sign up" copy
 */
AuthenticationView.prototype.toggleSignUpMode = function () {
    const quickCreateContext = getQuickCreateObject();
    const keyPrefix = this._isSignUpMode ? 'login_' : 'sign_up_';
    const $modal = $(this.getModalSelector());

    const buttonText = quickCreateContext[`${keyPrefix}button_text`];
    const facebookButtonText = quickCreateContext[`${keyPrefix}facebook_button_text`];

    $modal.find('.js-first-name').toggleClass('hidden');
    $modal.find('.js-auth-toggle').toggleClass('hidden');
    $modal.find('.js-auth-quick-fine-print').toggleClass('hidden');
    $modal.find('.js-tos-opt-in').toggleClass('hidden');
    $modal.find('.js-is-opted-in').toggleClass('hidden');
    $modal.find('.js-intro').text(quickCreateContext[`${keyPrefix}intro_text`]);

    // $.fn.button doesn't recognize the updated data attribute unless we use .attr
    $modal.find('.js-btn-primary').text(buttonText).attr('data-loading-text', buttonText);
    $modal.find('.js-btn-facebook').text(facebookButtonText).attr('data-loading-text', facebookButtonText);

    this._isSignUpMode = !this._isSignUpMode;

    $modal.find('[name="is_opted_in"]').prop('disabled', !this._isSignUpMode);
    $modal.find('[name="tos_opt_in"]').prop('disabled', !this._isSignUpMode);
};

export default AuthenticationView;
