import $ from 'jquery';

import { getRecaptchaSiteKey } from 'chairisher/context/auth';
import { getRecaptchaJSUrl } from 'chairisher/context/script';

let loadingPromise;

/**
 * Loads google's recaptcha library.
 *
 * @return {Promise<object>}
 */
function loadRecaptcha() {
    if (!loadingPromise) {
        loadingPromise = new Promise((resolve, reject) => {
            const rejectWrapper = () => {
                reject(new Error(`failed to load script "${getRecaptchaJSUrl()}"`));
            };

            const script = document.createElement('script');
            script.async = true;
            script.src = getRecaptchaJSUrl();
            script.onerror = rejectWrapper;
            script.onload = () => {
                if (window.grecaptcha) {
                    resolve(window.grecaptcha);
                } else {
                    rejectWrapper();
                }
            };
            document.head.appendChild(script);
        });
    }

    return loadingPromise;
}

/**
 * Executes recaptcha v3: gets a unique token from google and returns a deferred with the token and action name as
 * data. It will also embed the token and action into the form if corresponding inputs exist
 * @param {string} actionName: a string to identify what action the user took
 * @returns {jQuery.Deferred}
 */
export default function executeRecaptcha(actionName) {
    const deferred = $.Deferred();
    const siteKey = getRecaptchaSiteKey();
    if (siteKey) {
        loadRecaptcha().then((recaptcha) => {
            recaptcha.ready(() => {
                recaptcha.execute(siteKey, { action: actionName }).then((token) => {
                    // embed token in form and send to backend to verify on form submission
                    let recaptchaData = {};
                    if (token) {
                        $('#id_recaptcha_token').val(token);
                        $('#id_recaptcha_action').val(actionName);
                        recaptchaData = {
                            recaptcha_action: actionName,
                            recaptcha_token: token,
                        };
                    }
                    deferred.resolve(recaptchaData);
                });
            });
        });
    } else {
        deferred.resolve();
    }
    return deferred;
}
