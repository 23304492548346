import $ from 'jquery';

import { trackProductList2 } from 'chairisher/analytics/product';
import {
    AmplitudeEventProperty,
    UhpPosition,
    logAmplitudeEvent,
    logAmplitudeLinkClickEvent,
} from 'chairisher/util/analytics';
import { getBusinessRailData } from 'chairisher/context/business';

/**
 * Track a homepage module (e.g., 'Featured Collections' or 'Shop By Category')
 *
 * @param {string} selector Used to select the module
 * @param {string} ctaPosition Name of the module
 * @param {Function} nameSelectorCallback Callback used to extract title from link
 */
function trackModule(selector, ctaPosition, nameSelectorCallback) {
    $(selector).on('click', 'a', (e) => {
        const $link = $(e.currentTarget);
        logAmplitudeLinkClickEvent(nameSelectorCallback($link).trim(), $link.attr('href'), ctaPosition);
    });
}

/**
 * Track tiles within a given module as if each one were its own module
 *
 * @param {string} selector Used to select the module section
 * @param {string} ctaPosition Name of the module
 * @param {Function} nameSelectorCallback Callback used to extract title from link
 */
function trackModuleSections(selector, ctaPosition, nameSelectorCallback) {
    const numModuleSections = $(selector).length;
    for (let i = 0; i < numModuleSections; i += 1) {
        trackModule(`${selector}:eq(${i})`, `${ctaPosition} ${i + 1}`, nameSelectorCallback);
    }
}

/**
 * Tracks clicks on modules on the homepage using various callbacks to extract the correct title information
 *
 */
function trackModules() {
    /**
     * Create a function used to extract text from a tracked element
     *
     * @param {string} parentSelector The selector for the parent of the clicked link
     * @param {string} textSelector The selector for the element which houses the text
     * @return {Function} the function which will be used to select text
     */
    const createLinkTextSelector = (parentSelector, textSelector) => ($link) =>
        $link.closest(parentSelector).find(textSelector).text();

    const extractMainFeatureLinkText = createLinkTextSelector('.js-main-feature', '.js-story-title');
    const extractStoryGridLinkText = createLinkTextSelector('.js-story', '.js-story-title');
    const extractTileLinkText = createLinkTextSelector('.js-tile', '.js-tile-title');

    // Hardcoded selector because product rail links follow a different layout scheme
    // If data-product-title isn't found, then the 'View all' link was clicked.
    const extractProductRailLinkText = ($link) =>
        $link.closest('[data-product-title]').attr('data-product-title') || 'View all';

    trackModule('.js-main-feature', UhpPosition.HERO, extractMainFeatureLinkText);
    trackModule('.js-uhp-category-tiles', UhpPosition.SHOP_BY_CATEGORY, extractTileLinkText);
    trackModuleSections(
        '.js-uhp-featured-collections .js-story',
        UhpPosition.FEATURED_COLLECTIONS,
        extractStoryGridLinkText,
    );
    trackModule('.js-uhp-curators-picks-grid', UhpPosition.STAFF_PICKS, extractProductRailLinkText);
    trackModule('.js-uhp-new-arrivals', UhpPosition.NEW_ARRIVALS, extractProductRailLinkText);
    trackModuleSections('.js-uhp-portholes .js-story', UhpPosition.PORTHOLES, extractStoryGridLinkText);
    trackModule('.js-uhp-trade-module', UhpPosition.TRADE_MODULE, ($link) => $link.text());
    trackModule('.js-uhp-wholesale-featured', UhpPosition.WHOLESALE_RAIL, extractProductRailLinkText);

    // Designer Search CTA
    const $uhpDesignerSearch = $('.js-uhp-designer-search');
    $uhpDesignerSearch.on('click', '.js-designer-search-categories a', (e) => {
        const $link = $(e.currentTarget);
        logAmplitudeLinkClickEvent($link.text().trim(), $link.attr('href'), UhpPosition.DESIGNER_DIRECTORY);
    });

    $uhpDesignerSearch.on('click', '.js-story-grid a', (e) => {
        const $link = $(e.currentTarget);
        const $business = $link.closest('[data-business-id]');
        const businessId = $business.data('business-id');
        const businessData = getBusinessRailData()[businessId];

        const ctaName = $business.find('.js-story-title').text().trim();
        const ctaUrl = $link.attr('href');
        const ctaPosition = UhpPosition.DESIGNER_DIRECTORY;

        const amplitudeEventProperties = [];

        if (businessData) {
            amplitudeEventProperties[AmplitudeEventProperty.IS_DMA_MATCH] = !!businessData.is_dma_match;
        }

        logAmplitudeLinkClickEvent(ctaName, ctaUrl, ctaPosition, amplitudeEventProperties);
    });
}

/**
 * Tracks a homepage view
 */
export default function trackHomepageScreen() {
    trackProductList2({ listSelector: '#js-new-arrival-grid' });

    $('.js-cta-shop-sustainably').on('click', (e) => {
        const $link = $(e.target);

        logAmplitudeLinkClickEvent($link.text().trim(), $link.attr('href'), UhpPosition.SUSTAINABILITY_MODULE);
    });

    trackModules();
    logAmplitudeEvent('screen - home');
}
